*{
  margin: 0 ;
  padding: 0 ;
}
#root{
  width: 100vw;
  height: 100vh;
}
#webpack-dev-server-client-overlay {
  display: none !important;
}
@font-face {
  font-family: "iconfont"; /* Project id 4735755 */
  src: url('iconfont.woff2?t=1732610707024') format('woff2'),
       url('iconfont.woff?t=1732610707024') format('woff'),
       url('iconfont.ttf?t=1732610707024') format('truetype'),
       url('iconfont.svg?t=1732610707024#iconfont') format('svg');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-auth-feishu:before {
  content: "\e600";
}

.icon-cb-putongyonghu:before {
  content: "\e651";
}

.icon-tuiguangguanli:before {
  content: "\e786";
}

.icon-riqi:before {
  content: "\e65e";
}

.icon-shangchuan:before {
  content: "\e60d";
}

.icon-yonghu:before {
  content: "\e61c";
}

.icon-bumen:before {
  content: "\e686";
}

.icon-jiaoseguanli:before {
  content: "\e641";
}

.icon-shoujihaoma:before {
  content: "\e650";
}

.icon-dengludengchurizhi:before {
  content: "\e7a5";
}

.icon-shouye:before {
  content: "\e606";
}

.icon-xitongguanli:before {
  content: "\e60c";
}

.icon-erji-yonghuguanli:before {
  content: "\eaa3";
}

.icon-yonghuguanli:before {
  content: "\e6aa";
}

.icon-neirongguanli:before {
  content: "\e60b";
}

.icon-caiwuguanli:before {
  content: "\e7e4";
}

.icon-dingdanguanli:before {
  content: "\e6e7";
}

.icon-neirongguanli-01:before {
  content: "\e613";
}


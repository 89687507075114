.bag-c{
    border: 1px solid #ccc;
    background-color: #FFFFFF;
}
.bag-b{
    border:1px solid  #3072be;
    background-color: #FFFFFF;
}
body {
  .arco-input,.arco-input-inner-wrapper {
    background: transparent;
    border: 1px solid #ccc;
  }
  .arco-input-disabled {
    background: rgb(242,243,245);
  }
  .arco-picker {
    background: transparent;
    border: 1px solid #ccc;
  }
  .arco-table-th {
    background: #f8fafd;
  }
  .arco-select .arco-select-view {
    background: transparent;
    border: 1px solid #ccc;
  }
  .arco-textarea {
    background: transparent;
    border: 1px solid #ccc;
  }
}
body {
  font-family: PingFang SC;
  .arco-form-item {
    margin-bottom: 16px;
  }
  .arco-form-layout-vertical > .arco-form-label-item {
    margin-bottom: 4px;
  }
}
* {
  box-sizing: border-box;
}


// .arco-input-inner-wrapper{
//     background-color: #FFFFFF !important; 
//     border: 1px solid #ccc;
// }
.modal_GL_data{
    .arco-modal-title{
        text-align: left !important;
    }
}

.arco-modal-footer{
    border-top: none !important;
}

::-webkit-scrollbar {
    width: 6px; /* 设置滚动条的宽度 */
    height: 6px; /* 设置水平滚动条的高度 */
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: #ccc; /* 滚动条滑块颜色 */
    border-radius: 10px; /* 滚动条滑块圆角 */
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background-color: #555; /* 当用户悬停时滑块的颜色 */
  }
  
  ::-webkit-scrollbar-track {
    background: #f1f1f1; /* 滚动条轨道颜色 */
    border-radius: 10px; /* 滚动条轨道圆角 */
  }
  

